import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';

import { LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export const searchBar = async (params?: any): Promise<any> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.SearchBar, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.SearchBar];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        ...resource,
        isLoaded: true,
    };
};
