import { ReactNode } from 'react';

import { ListProps as ButtonListProps } from 'components/layout/Button';
import { Props as LayoutContainerProps } from 'components/layout/LayoutContainer';

import Component from './component';

export enum Sizes {
    XSmall = 'xsmall',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}
export interface Props {
    background?: string;
    backgroundMobile?: string;
    headline?: string;
    subheadline?: string;
    buttons?: ButtonListProps[];
    layoutContainerProps?: LayoutContainerProps;
    size?: Sizes;
    children?: ReactNode;
    email?: string;
}

Component.defaultProps = {
    background: null,
    backgroundMobile: null,
    headline: null,
    subheadline: null,
    buttons: [],
    layoutContainerProps: {},
    size: Sizes.Large,
    children: null,
};
