import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    position: relative;

    .input-body {
        position: relative;

        .input-hints {
            position: absolute;
            background-color: #FFFFFF;
            width: 100%;
            top: calc(100% + 1em);
            border-radius: 0.5em;
            box-shadow: 0 1em 1em #484a521a;
            display: flex;
            flex-direction: column;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #ffefdf;
                box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 20%)
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 9px;
                box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 20%);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #ba9449;
            }

            .autocomplete {
                display: flex;

                img {
                    width: 1.3em;
                    margin-right: 0.4em;
                    opacity: 0.1;
                }

                strong {
                    color: #ba9449;
                }

                &:hover > img {
                    opacity: 1;
                }
            }

            .hint {
                padding: 0.4em 1em;

                &.nohover {
                    opacity: 0.4;
                }

                @media (pointer: fine) {
                    &:not(&.nohover) {
                        cursor: pointer;

                        &:hover {
                            background-color: #f3e9dc;
                        }
                    }
                }
            }
        }

        .input-hints-autocomplete {
            width: calc(100% + 2.7em);
            left: -2.7em;
        }
    }

    input {
        width: 100%;
        height: 3.5em;
        border: none;
        padding: 0 1em;
        color: ${variables.colorText};
        border-radius: .5em;
        font-size: .9em;
        outline: none;

        &::placeholder {
            color: ${variables.colorTextLight};
        }

        &:disabled {
            background-color: #d7e2e4;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */

        &[type=number] {
            -moz-appearance: textfield;
        }
    }

    .error-message {
        display: inline-block;
        position: relative;
        left: 0;
        bottom: unset;
        color: tomato;
        font-size: 0.8em;
    }

    &.decorator-left {
        input {
            padding-left: 2.5em;
        }
    }

    &.decorator-right {
        input {
            padding-right: 2.5em;
        }
    }

    &.style-secondary {
        input {
            border: .1em solid #F1F1F1;

            &:disabled {
                opacity: 0.5;
                background-color: transparent;
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .input-body {
            position: relative;

            .input-hints {
                .hint {
                    padding: 0.6em 1em;
                    text-align: left;
                }
            }
        }
    }
`;
