import { Component, ReactNode } from 'react';

import { Props, State } from './index';
import StyledComponent from './styles';

export default class LayoutFormsReactForm extends Component<Props> {
    static defaultProps = {
        children: null,
        errorMessage: null,
        formData: {},
        innerRef: null,
        onSuccess: (response: object) => response,
        onError: (error: object): any => error,
        onSubmitData: (values: object): object => values,
        onChange: (values: object): any => values,
        submitAction: null,
    };
    state: State = {
        globalErrorMessage: null,
    };


    onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
        e.preventDefault();
        const { submitAction, onSubmitData, onSuccess, onError, formData } = this.props;

        try {
            const response = await submitAction({
                ...onSubmitData(formData),
            });

            if (!response) {
                console.error('No response');
                throw new Error('No response');
            }

            return onSuccess(response);
        } catch (error) {
            console.error('onSubmit error', error);

            return onError(error);
        }
    };

    render = (): ReactNode => {
        const { children, errorMessage } = this.props;
        const { globalErrorMessage } = this.state;

        return (
            <StyledComponent className="layout-react-form">
                <form
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                >
                    {(errorMessage || globalErrorMessage) && (
                        <div className="form-error-message">
                            {errorMessage || globalErrorMessage}
                        </div>
                    )}
                    {children}

                    {/* Hack for chrome autocomplete: https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off */}
                    <input
                        type="text"
                        autoComplete="on"
                        value=""
                        style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
                        onChange={() => null}
                    />
                </form>
            </StyledComponent>
        );
    };
}
