import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: .5em;
    position: relative;

    .layout-forms-input-error {
        position: absolute;
        top: 100%;
    }

    .label-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .4em;

        .label-body {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .layout-forms-input-label {
                color: #FFFFFF;
                opacity: 0;
                transition: opacity .3s ease-in-out;
                transform: translateY(1em);
            }
        }

        .label-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }


        .layout-forms-input-helper {
            margin-right: .5em;
        }
    }

    .internal-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;
        position: relative;
        border-radius: .5em;

        .input-decorator {
            position: absolute;
            top: 0;
            z-index: 2;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 .5em;
            font-weight: 700;
            color: ${vars.colorText};

            svg {
                width: 1em;
            }

            &.input-decorator-left {
                left: 0;
                border-top-left-radius: .5em;
                border-bottom-left-radius: .5em;
            }

            &.input-decorator-right {
                right: 0;
                border-top-right-radius: .5em;
                border-bottom-right-radius: .5em;
            }

            &.input-decorator-state-info {
                background-color: transparent;
                color: #CCCCCC;
            }

            &.input-decorator-state-success {
                background-color: ${vars.colorSuccess};
                color: ${vars.colorBackgroundContrast};
            }

            &.input-decorator-state-warning {
                background-color: ${vars.colorWarning};
                color: ${vars.colorBackgroundContrast};
            }

            &.input-decorator-state-alert {
                background-color: ${vars.colorAlert};
                color: ${vars.colorBackgroundContrast};
            }
        }

        .input-body {
            width: 100%;

            &.left-decorator-error {
                padding-left: 1.5em;
            }
        }
    }

    &.error {
        .internal-wrapper {
            border-radius: .8em;

            .input-body {
                border-color: #eb8817 !important;

                .checkbox {
                    & + .checkbox-trigger {
                        border-color: #eb8817 !important;
                    }
                }
            }
        }
    }

    &.focus {
        .label-wrapper {
            .label-body {
                .layout-forms-input-label {
                    opacity: 1;
                }
            }
        }
    }
`;
