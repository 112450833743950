import { FunctionComponent, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ColorStates } from 'types/states';

import InputHelper from 'components/layout/forms/InputHelper';
import InputLabel from 'components/layout/forms/InputLabel';
import SpinnerNew, { Colors, Positions, Sizes } from 'components/layout/SpinnerNew';

const Tooltip = dynamic(() => import('components/layout/Tooltip'));


import { InputDecorator, Props, ValidationActions } from './index';
import StyledComponent from './styles';

const LayoutFormsInputWrapper: FunctionComponent<Props> = ({ name, children, label, helper, error, validationAction, decoratorLeft, decoratorRight, disabled, isFocused }) => {
    const leftDecoratorError = error && validationAction === ValidationActions.Decorator && !decoratorLeft;

    const getLeftDecorator = (): ReactNode => {
        if (error && validationAction === ValidationActions.Decorator) {
            return (
                <div
                    className={classnames(
                        'input-decorator',
                        'input-decorator-left',
                        `input-decorator-state-${ColorStates.Warning}`
                    )}
                >
                    {getIcon({
                        visible: true,
                        state: ColorStates.Warning,
                    })}
                </div>
            );
        }

        if (decoratorLeft && decoratorLeft.visible !== false) {
            return (
                <div
                    className={classnames(
                        'input-decorator',
                        'input-decorator-left',
                        `input-decorator-state-${decoratorLeft.state || ColorStates.Info}`
                    )}
                >
                    {decoratorLeft.loading
                        ? (
                            <SpinnerNew
                                size={Sizes.Small}
                                position={Positions.Relative}
                                color={Colors.White}
                            />
                        )
                        : getIcon(decoratorLeft)}
                </div>
            );
        }

        return null;
    };

    const getRightDecorator = (): ReactNode => {
        if (decoratorRight && decoratorRight.visible !== false) {
            return (
                <div
                    className={classnames(
                        'input-decorator',
                        'input-decorator-right',
                        `input-decorator-state-${decoratorRight.state || ColorStates.Info}`
                    )}
                >
                    {decoratorRight.loading
                        ? (
                            <SpinnerNew
                                size={Sizes.Small}
                                position={Positions.Relative}
                                color={Colors.White}
                            />
                        )
                        : getIcon(decoratorRight)}
                </div>
            );
        }

        return null;
    };

    const getIcon = (decorator: InputDecorator): ReactNode => {
        if (decorator.children) {
            return decorator.children;
        }

        if (decorator.state) {
            switch (decorator.state) {
                case ColorStates.Success:
                    return (
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="check-icon"
                        />
                    );
                case ColorStates.Warning:
                    return (
                        <Tooltip
                            name={name}
                            text={error}
                        >
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </Tooltip>
                    );
                case ColorStates.Alert:
                    return (
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="times-icon"
                        />
                    );
            }
        }

        return null;
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input-wrapper',
                {
                    error: Boolean(error),
                    focus: isFocused,
                }
            )}
        >
            {(label || helper) && (
                <div className="label-wrapper">
                    <div className="label-body">
                        {helper && (
                            <InputHelper
                                name={name}
                                text={helper}
                            />
                        )}
                        {label && (
                            <InputLabel>
                                {label}
                            </InputLabel>
                        )}
                    </div>
                </div>
            )}
            <div className="internal-wrapper">
                {(!disabled || error) && getLeftDecorator()}
                <div
                    className={classnames(
                        'input-body',
                        { 'left-decorator-error': leftDecoratorError }
                    )}
                >
                    {children}
                </div>
                {(!disabled) && getRightDecorator()}
            </div>
        </StyledComponent>
    );
};

export default LayoutFormsInputWrapper;
