import Component from './component';

export interface Props {
    children: any;
    errorMessage?: string;
    initialValues?: object;
    innerRef?: any;
    formData?: object;
    submitAction: (values: { [key: string]: any }) => any;
    onSubmitData?: (values: any) => object;
    onSuccess?: (response: any) => any;
    onError?: (error: any) => any;
    onChange?: (formik: any, values: any) => any;
}

export interface State {
    globalErrorMessage?: string;
}


export default Component;