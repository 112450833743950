import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    position: relative;

    .form-searchbar {
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-radius: .5em;
        padding: 0.4em;
        transition: background-color 300ms ease-in-out;

        &:focus-within,
        &:hover {
            background-color: rgba(255, 255, 255, 0.7);

            .layout-button {
                .button {
                    opacity: 1;
                }
            }
        }

        .icon {
            padding: 0 .5em;
        }

        .buttons-container {
            position: absolute;
            right: 0.4em;
            top: 0.4em;
            display: flex;

            .layout-button {
                &.clear-input-btn {
                    .button {
                        position: relative;
                        padding: 0.4em;
                        margin-right: 0.4em;
                        border-right: 1px solid #797979;
                        border-radius: 0;
                        background-color: transparent;
                        color: #494949;
                        min-height: 24px;
                        min-width: 36px;
                        opacity: 0.5;
                        transition: opacity 200ms ease-in-out;

                        &:after,
                        &:before {
                            position: absolute;
                            content: '';
                            transform: translate(-50%, -50%);
                            width: 16px;
                            height: 2px;
                            background-color: black;
                            inset: 50% 0 0 50%;
                            transition: transform 100ms ease-in-out;
                        }

                        &:after {
                            transform: translate(-50%, -50%) rotate(45deg) scale(0.8);
                        }

                        &:before {
                            transform: translate(-50%, -50%) rotate(-45deg) scale(0.8);
                        }

                        @media (pointer: fine) {
                            &:hover {
                                opacity: 1;
                                background-color: transparent;

                                &:after,
                                &:before {
                                    inset: 50% 0 0 50%;
                                }

                                &:after {
                                    transform: translate(-50%, -50%) rotate(45deg) scale(1);
                                }

                                &:before {
                                    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
                                }
                            }
                        }

                        &:active {
                            &:after {
                                transform: translate(-50%, -50%) rotate(135deg) scale(0.6);
                            }

                            &:before {
                                transform: translate(-50%, -50%) rotate(-135deg) scale(0.6);
                            }
                        }
                    }
                }

                .button {
                    font-size: 1em;
                    background-color: #BA9449;
                    z-index: 1;

                    &:after {
                        z-index: -1;
                        content: '';
                        inset: 0;
                        position: absolute;
                        background-color: inherit;
                        border-radius: inherit;
                        transition: inset 200ms ease-in-out;
                    }

                    &:hover {
                        background-color: #BA9449;
                        opacity: 1;

                        &:after {
                            inset: -3px;
                        }
                    }
                }
            }
        }
    }

    .layout-forms-input-wrapper {
        margin-bottom: 0;
        border: none;

        .input {
            height: 3em;
            color: #444444;
            background-color: rgba(255, 255, 255, 0);
            padding: 0;
            max-width: 60%;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
            }

            &::placeholder {
                color: currentColor;
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        .form-searchbar {
            .buttons-container {
                height: calc(100% - 0.8em);

                .layout-button {
                    .button {
                        height: 100%;
                    }
                }
            }
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .layout-forms-input-wrapper {
            .input {
                max-width: 55%;
            }
        }
    }
`;
