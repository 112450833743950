import { ChangeEvent, ClipboardEvent, FunctionComponent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';
import NewImage from 'components/layout/NewImage';
import useOnClickOutside from 'hooks/useOnClickOutside';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsReactInput: FunctionComponent<Props> = ({
    name,
    label,
    type,
    placeholder,
    helper,
    value,
    onChange,
    hints,
    selectedHint,
    style,
    styles,
    disabled,
    error,
    validationAction,
    decoratorLeft,
    decoratorRight,
    required,
    autocomplete = false,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [filterHints, setFilterHints] = useState<string[]>([]);
    const [hintsFocused, setHintsFocused] = useState<boolean>(false);
    const focusRef = useRef(null);

    const selectHint = (hint: string) => {
        selectedHint(hint);
        setHintsFocused(false);
    };

    const filterThroughHints = (e) => {
        const filterHintsFiltered = hints?.filter((hint) => hint.toLowerCase()
            .startsWith(e.target.value.toLowerCase()));

        setFilterHints(filterHintsFiltered);
    };

    const hintsRef = useOnClickOutside<HTMLDivElement>({
        handler: () => {
            setHintsFocused(false);
        },
    });

    useEffect(() => {
        if (hints?.length > 0) {
            setFilterHints(hints);
        }
    }, [hints]);

    const onKeyUp = (e: KeyboardEvent): void => {
        setHintsFocused(true);
        if (e.key === 'Enter') {
            setHintsFocused(false);
        }
    };

    const renderHintText = (hint: string, searchTerm: string | number): string | JSX.Element => {
        if (searchTerm && typeof searchTerm === 'string') {
            const hintLowerCase = hint.toLowerCase();
            const searchTermLowerCase = searchTerm.toLowerCase();
            const index = hintLowerCase.indexOf(searchTermLowerCase);
            const first = hint.substring(0, index);
            const second = hint.substring(index, (index + searchTerm.length));
            const third = hint.substring(index + searchTerm.length);
            return (<span>{first}<strong>{second}</strong>{third}</span>);
        }
        return hint;
    };

    const renderDropdown = (renderAutocompleteDropdown: boolean) => {
        if (renderAutocompleteDropdown) {
            return ((hints && filterHints && hintsFocused) && (
                <div
                    ref={hintsRef}
                    className="input-hints input-hints-autocomplete"
                >
                    {filterHints.map((hint, index) => (
                        <div
                            ref={index === 0 ? focusRef : null}
                            onClick={() => selectHint(hint)}
                            key={hint}
                            className="hint autocomplete"
                        >
                            <NewImage src="/images/home/search.svg" />
                            {renderHintText(hint, value)}
                        </div>
                    ))}
                </div>
            ));
        }

        return ((hints && filterHints && hintsFocused) && (
            <div
                ref={hintsRef}
                className="input-hints"
            >
                {filterHints.length === 0 && (
                    <div className="hint nohover">
                        {value}...
                    </div>
                )}
                {filterHints.map((hint) => (
                    <div
                        onClick={() => selectHint(hint)}
                        key={hint}
                        className="hint"
                    >
                        {hint}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-input',
                [
                    `style-${style}`,
                    `name-${name}`,
                ],
                {
                    'error': Boolean(error),
                    'decorator-left': Boolean(
                        error && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                label={label}
                helper={helper}
                error={error}
                validationAction={validationAction}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
                disabled={disabled}
                isFocused={isFocused}
            >
                <input
                    required={required}
                    className="input"
                    name={name}
                    type={type}
                    autoComplete="off"
                    placeholder={placeholder}
                    onFocus={() => {
                        setIsFocused(true);
                        setHintsFocused(true);
                    }}
                    onBlur={() => setIsFocused(false)}
                    value={(value === null ? '' : value)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onChange(event);

                        if (hints && hints.length >= 0) {
                            filterThroughHints(event);
                        }
                    }}
                    onPaste={(event: ClipboardEvent<HTMLInputElement>): void => {
                        onChange(event);

                        if (hints && hints.length >= 0) {
                            filterThroughHints(event);
                        }
                    }}
                    onKeyUp={onKeyUp}
                    disabled={disabled}
                />
                {renderDropdown(autocomplete)}
            </InputWrapper>
            {error && <small className="error-message">{error}</small>}
        </StyledComponent>
    );
};

export default LayoutFormsReactInput;
