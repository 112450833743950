import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    top: calc(100% + 0.5em);
    position: absolute;

    .drop-down-container {
        background-color: rgba(255, 255, 255, 1);
        padding: 0;
        min-height: 50px;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-shadow: 0 1em 1.5em -0.9em #7b6c63;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms ease;
        overflow: hidden;

        &.active-hints {
            opacity: 1;
            pointer-events: all;
        }

        .phrase {
            cursor: pointer;
            font-size: 0.9em;
            border-bottom: 1px solid #f3f3f3;
            padding: 1em 0 1em 1.2em;
            display: flex;
            gap: 0.5em;

            .info {
                font-size: 12px;
                position: absolute;
                right: 1em;
                top: 1.5em;
                opacity: 0.3;
            }

            .icon {
                opacity: 0.2;
            }

            @media (pointer: fine) {
                &:hover {
                    background-color: #f3f3f3;

                    .icon {
                        opacity: 0.8;
                    }
                }
            }

        }

        &.hide-info {
            .info {
                display: none;
            }
        }

        &.hide-drop-down {
            opacity: 0;
            pointer-events: none;
        }

        .drop-down-list {
            .title {
                opacity: 0.4;
                margin: 1em 0;
                padding-left: 1.2em;
                font-size: 0.9em;
                cursor: default;
            }

            .drop-down-list-item {

                .item {
                    padding: 0.5em 0 0.5em 1.2em;
                    cursor: pointer;
                    font-size: 0.9em;
                    display: flex;
                    align-items: center;
                    gap: 0.5em;

                    .gender {
                        font-size: 10px;
                        opacity: 0.4;
                    }

                    .matched-phrase {
                        b {
                            color: #BA9449;
                            font-weight: 700;
                        }
                    }

                    .icon {
                        opacity: 0.2;
                    }
                }

                @media (pointer: fine) {
                    &:hover {
                        background-color: #f3f3f3;

                        .item {
                            .icon {
                                opacity: 0.8;
                            }
                        }
                    }
                }

                &:last-of-type {
                    .item {
                        padding-bottom: 1em;
                    }
                }
            }
        }
    }
`;
