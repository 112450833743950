import { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Genders } from 'models/Product';

import { imageResizer } from 'utils/image';
import { withVariables } from 'utils/string';

import SpinnerNew from 'components/layout/SpinnerNew';

import StyledComponent from './styles';
import { ModelType, Props } from './types';

const PublicLayoutFormsSearchbarFormDropDown: FunctionComponent<Props> = ({
    hintsList,
    phrase,
    onSuccess,
    isFocused,
    translations,
    isLoading,
}) => {
    const [hideDropDown, setHideDropDown] = useState<boolean>(false);
    const router = useRouter();
    const [filteredHints, setFilteredHints] = useState({
        phrase,
        articles: [],
        products: [],
        locations: [],
    });

    useEffect(() => {
        setHideDropDown(false);
        setFilteredHints({
            phrase,
            articles: [...filteredHints.articles],
            products: [...filteredHints.products],
            locations: [...filteredHints.locations],
        });
    }, [phrase]);

    useEffect(() => {
        const filteredProducts = hintsList.filter((hint: { modelType: ModelType }) => hint.modelType === ModelType.Product);
        const filteredOffers = hintsList.filter((hint: { modelType: ModelType }) => hint.modelType === ModelType.Offer);
        const filteredArticles = hintsList.filter((hint: { modelType: ModelType }) => hint.modelType === ModelType.Article);
        const filteredLocations = hintsList.filter((hint: { modelType: ModelType }) => hint.modelType === ModelType.Location);

        setFilteredHints({
            phrase,
            products: [...filteredProducts, ...filteredOffers],
            articles: [...filteredArticles],
            locations: [...filteredLocations],
        });
    }, [hintsList]);

    useEffect(() => {
        if (isFocused) {
            return setHideDropDown(false);
        }

        setHideDropDown(true);
    }, [isFocused]);

    const renderHintText = (hint: string, searchTerm: string | number): string | JSX.Element => {
        if (searchTerm && typeof searchTerm === 'string') {
            const hintLowerCase = hint.toLowerCase();
            const searchTermLowerCase = searchTerm.toLowerCase();
            const index = hintLowerCase.indexOf(searchTermLowerCase);

            if (index === -1) return hint;

            const first = hint.substring(0, index);
            const second = hint.substring(index, (index + searchTerm.length));
            const third = hint.substring(index + searchTerm.length);
            return (<span className="matched-phrase">{first}<b>{second}</b>{third}</span>);
        }
        return hint;
    };

    const getGenderZoneSlug = (gender: Genders): string => {
        switch (gender) {
            case Genders.Male:
                return translations.genderZone.male;
            default:
                return translations.genderZone.female;
        }
    };

    const renderProducts = () => {
        return (
            <ul className="drop-down-list">
                <p className="title">{translations.dropdown.productsTitle}</p>
                {filteredHints.products.map((hint, index) => {
                    const name = hint.model.locale.name;
                    const gender = translations.gender[hint.model.gender];

                    if (index < 5) return (
                        <li
                            key={hint.model.name}
                            className="drop-down-list-item"
                        >
                            <div
                                className="item"
                                onClick={() => {
                                    router.push(withVariables(Routes.PublicProduct, {
                                        genderZoneSlug: getGenderZoneSlug(hint.model.gender),
                                        slug: hint.model.locale.slug,
                                    }));
                                }}
                            >
                                <img
                                    className="icon"
                                    src={imageResizer('/images/home/search.svg', { width: 16 })}
                                    loading="lazy"
                                    alt="search"
                                    width="16px"
                                    height="16px"
                                />
                                {renderHintText(name, phrase)}
                                <span className="gender">
                                    ({gender})
                                </span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderArticles = () => {
        return (
            <ul className="drop-down-list">
                <p className="title">{translations.dropdown.articlesTitle}</p>
                {filteredHints.articles.map((hint, index) => {
                    const title = hint.model.locale.title;
                    const categorySlug = hint.model.category.locale.slug;
                    const articleSlug = hint.model.locale.slug;

                    if (index < 5) return (
                        <li
                            key={hint.model.locale.title}
                            className="drop-down-list-item"
                        >
                            <div
                                className="item"
                                onClick={() => {
                                    router.push(withVariables(Routes.PublicBlogArticle, {
                                        articleCategorySlug: categorySlug,
                                        slug: articleSlug,
                                    }));
                                }}
                            >
                                <img
                                    className="icon"
                                    src={imageResizer('/images/home/search.svg', { width: 21 })}
                                    loading="lazy"
                                    alt="search"
                                    width="16px"
                                    height="16px"
                                />
                                {renderHintText(title, phrase)}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderLocations = () => {
        return (
            <ul className="drop-down-list">
                <p className="title">{translations.dropdown.locationsTitle}</p>
                {filteredHints.locations.map((hint, index) => {
                    const name = hint.model.name;
                    const address = hint.model.address;
                    const localeSlug = hint.model.locale.slug;

                    if (index < 5) return (
                        <li
                            key={address}
                            className="drop-down-list-item"
                        >
                            <div
                                className="item"
                                onClick={() => {
                                    router.push(withVariables(Routes.PublicLocation, {
                                        slug: localeSlug,
                                    }));
                                }}
                            >
                                <img
                                    className="icon"
                                    src={imageResizer('/images/home/search.svg', { width: 21 })}
                                    loading="lazy"
                                    alt="search"
                                    width="16px"
                                    height="16px"
                                />
                                {renderHintText(name || address, phrase)}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <StyledComponent className="public-layout-forms-searchbar-form-drop-down">
            <div
                className={classnames({
                    'drop-down-container': true,
                    'active-hints': filteredHints.phrase.length > 0,
                    'hide-info': filteredHints.phrase.length > 2,
                    'hide-drop-down': hideDropDown,
                })}
            >
                <div
                    className="phrase"
                    onClick={() => onSuccess({ query: filteredHints.phrase })}
                >
                    <img
                        className="icon"
                        src={imageResizer('/images/home/search.svg', { width: 21 })}
                        loading="lazy"
                        alt="search"
                        width="16px"
                        height="16px"
                    />
                    {filteredHints.phrase}...
                    <span className="info">
                        {translations.dropdown.info}
                        {` ${3 - phrase.length} `}
                        {phrase.length > 1 ? translations.dropdown.infoSingular : translations.dropdown.infoPlural}
                    </span>
                </div>
                {isLoading ? (
                    <SpinnerNew />
                ) : (
                    <>
                        {filteredHints.locations.length > 0 && renderLocations()}
                        {(filteredHints.products.length > 0) && renderProducts()}
                        {filteredHints.articles.length > 0 && renderArticles()}
                    </>
                )}

            </div>
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchbarFormDropDown;

