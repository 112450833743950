import Component from './component';
import { Sizes } from './types';

Component.defaultProps = {
    background: null,
    backgroundMobile: null,
    headline: null,
    subheadline: null,
    buttons: [],
    layoutContainerProps: {},
    size: Sizes.Large,
    children: null,
};

export default Component;
