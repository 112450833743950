import React, { useEffect, useRef } from 'react';

interface UseOnClickOutsideConfig {
    handler: (event: MouseEvent) => void,
    skipKeyboard?: boolean
}

export default function useOnClickOutside<T extends HTMLElement>(config: UseOnClickOutsideConfig, refOverride?: React.RefObject<T>) {
    const ref = useRef<T>(null);
    useEffect(
        () => {
            const current = refOverride?.current ?? ref.current;
            const listener = (event: MouseEvent) => {
                if (current && !current.contains(event.target as Element)) {
                    config.handler(event);
                }
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, refOverride, config.handler],
    );
    if (refOverride === undefined)
        return ref;
    return;
};
