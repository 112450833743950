interface Translations {
    dropdown: {
        info: string;
        infoSingular: string;
        infoPlural: string;
        productsTitle: string;
        articlesTitle: string;
        locationsTitle: string;
    }
    genderZone: {
        male: string;
        female: string;
    }
    gender: {
        male: string;
        female: string;
    }
}

export interface Props {
    hintsList: [];
    phrase: string;
    onSuccess?: (response) => any;
    isFocused: boolean;
    translations: Translations;
    isLoading: boolean;
}

export enum ModelType {
    Article = 'article',
    Product = 'product',
    Offer = 'offer',
    Location = 'location',
}
