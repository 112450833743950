import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div(({ overrideStyles }: { overrideStyles?: string }) => {
    return `
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .layout-container {
            position: relative;
            z-index: 2;
        }

        .copywriting {
            text-align: center;
            color: white;
            padding-top: 5em;


            .headline {
                font-size: 1.4em;
                font-weight: 700;
                font-family: ${vars.fontPrimary} !important;
                font-weight: 700;

                * {
                    font-family: ${vars.fontPrimary} !important;
                    font-weight: 700;
                }
            }

            .subheadline {
                font-size: 1.2em;
                padding-top: 1em;
                line-height: 1.4;
            }
        }

        &.size-xsmall {
            height: 30vh;
            max-height: 20em;
        }

        &.size-small {
            height: 50vh;
            max-height: 30em;
        }

        &.size-medium {
            height: 45vh;
            max-height: 35em;
        }

        &.size-large {
            height: 36em;
            max-height: 50em;
        }

        @media all and (min-width: ${vars.desktopXL}) {

            &.size-large {
                height: 50em;
                max-height: 50em;
            }
        }


        @media all and (max-width: ${vars.tabletL}) {
            height: auto;
            max-height: none;
            padding: 10em 0;

            .copywriting {
                padding-top: 0;

                .subheadline {
                    font-size: 1.3em;
                    margin-top: 1em;
                }
            }
        }

        @media all and (max-width: ${vars.mobileM}) {
            .copywriting {
                text-align: center;
                color: white;
                padding-top: 5em;

                .headline {
                    font-size: 16px;
                    font-weight: 700;
                }

                .subheadline {
                    font-size: 18px;

                    br {
                        display: none;
                    }
                }
            }

            &.size-large {
                height: 100vh;
                max-height: 60em;
            }
        }
        ${overrideStyles}
    `;
});
