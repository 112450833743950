import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { imageResizer } from 'utils/image';

import vars from 'theme/styles/vars';

import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutSectionHero: FunctionComponent<Props> = ({
    background,
    backgroundMobile,
    headline,
    subheadline,
    layoutContainerProps,
    size,
    children,
    email,
}) => {
    return (
        <StyledComponent
            className={classnames(
                'public-layout-section-hero',
                `size-${size}`,
                background && 'has-background',
            )}
            overrideStyles={`
                .bg {
                    background-image: url(${imageResizer(background, { width: 1920, quality: 85 })});
                }

                @media all and (max-width: ${vars.tabletS}) {
                    .bg {
                        background-image: url(${imageResizer(backgroundMobile, { width: 768, quality: 85 })});
                    }
                }

                @media all and (max-width: ${vars.mobileM}) {
                    .bg {
                        background-image: url(${imageResizer(backgroundMobile, { width: 480, quality: 85 })});
                    }
                }
            `}
        >
            <div
                className={classnames(
                    'bg',
                    { 'has-mobile': Boolean(backgroundMobile) }
                )}
            />
            <LayoutContainer {...layoutContainerProps}>
                <div className="copywriting">
                    <div
                        className="headline"
                        dangerouslySetInnerHTML={{ __html: headline }}
                    />
                    {subheadline && (
                        <div
                            className="subheadline"
                            dangerouslySetInnerHTML={{ __html: subheadline + (email || '') }}
                        />
                    )}
                </div>
                {children && children}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicLayoutSectionHero;
