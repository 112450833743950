import { ReactNode } from 'react';

import { ColorStates } from 'types/states';

import Component from './component';

export interface Props {
    name: string;
    children?: ReactNode;
    label?: string;
    helper?: string;
    error?: ReactNode;
    disabled?: boolean;
    validationAction?: ValidationActions;
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
    isFocused?: boolean;
}

export interface InputDecorator {
    visible?: boolean;
    state?: ColorStates;
    children?: ReactNode;
    loading?: boolean;
}

export enum ValidationActions {
    None = 'none',
    Decorator = 'decorator',
}

Component.defaultProps = {
    children: null,
    label: null,
    helper: null,
    error: null,
    disabled: false,
    validationAction: ValidationActions.Decorator,
    decoratorLeft: null,
    decoratorRight: null,
    isFocused: false,
};

export default Component;
