import { ChangeEvent, FunctionComponent, useContext, useRef, useState } from 'react';

import { imageResizer } from 'utils/image';
import Tracking from 'services/Tracking';

import { searchBar } from 'requests/searchBar';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonSizes, ButtonTypes } from 'components/layout/Button';
import Form from 'components/layout/forms/ReactForm';
import Input from 'components/layout/forms/ReactInput';
import useOnClickOutside from 'hooks/useOnClickOutside';

import SearchbarFormDropDown from '../SearchbarFormDropDown';

import StyledComponent from './styles';
import { FormData, Props } from './types';

const PublicLayoutFormsSearchbarForm: FunctionComponent<Props> = ({
    onSuccess,
    initialValues,
    translations,
}) => {
    const localeContext = useContext(LocaleContext);
    translations = {
        ...localeContext?.translations?.componentSearchForm,
        ...(translations || {}),
    };
    const initVal = initialValues?.search.trim();
    const [formData, setFormData] = useState<FormData>({ search: initVal ?? '' });
    const [hintsList, setHintsList] = useState<[]>([]);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const timeoutRef = useRef(null);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
        const userInput = event.currentTarget.value;
        setFormData({ search: userInput });

        if (userInput.length > 2) {
            return timeoutRef.current = window.setTimeout(() => {
                setIsLoading(true);
                searchBar({
                    keyword: userInput,
                    perPage: 100,
                    page: 1,
                })
                    .then((response) => {
                        const elements = response.elements;
                        setIsLoading(false);

                        setHintsList(elements);
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        console.error(err);
                    });
            }, 500);
        }
        setHintsList([]);
    };

    const selectContainerRef = useOnClickOutside<HTMLDivElement>({
        handler: () => {
            setIsFocused(false);
        },
    });

    const searchInputCleaner = () => {
        setFormData({ search: '' });
        setHintsList([]);
    };
    return (
        <StyledComponent
            className="public-layout-forms-searchbar-form"
            onFocus={() => setIsFocused(true)}
            ref={selectContainerRef}
        >
            <Form
                formData={formData}
                submitAction={async (values) => ({ query: values.search || null })}
                onSuccess={(response: any) => {
                    Tracking.eventSiteSearch(response.query);
                    onSuccess(response);
                }}
            >
                <div className="form-searchbar">
                    <img
                        className="icon"
                        src={imageResizer('/images/home/search.svg', { width: 21 })}
                        loading="lazy"
                        alt={translations?.imgAlt}
                        width="40px"
                        height="40px"
                    />
                    <Input
                        name="search"
                        placeholder={translations?.input?.search?.placeholder}
                        onChange={(e) => onChange(e)}
                        value={formData.search}
                        autocomplete
                    />
                    <div className="buttons-container">
                        <Button
                            className="clear-input-btn"
                            type={ButtonTypes.Button}
                            size={ButtonSizes.Medium}
                            onClick={() => searchInputCleaner()}
                        />
                        <Button
                            className="control"
                            type={ButtonTypes.Submit}
                            size={ButtonSizes.Medium}
                        >
                            {translations?.buttonLabel}
                        </Button>
                    </div>
                </div>
            </Form>
            {isFocused && (
                <SearchbarFormDropDown
                    hintsList={hintsList}
                    phrase={formData.search}
                    onSuccess={onSuccess}
                    isFocused={isFocused}
                    translations={{
                        genderZone: translations?.genderZone,
                        dropdown: translations?.dropdown,
                        gender: translations?.gender,
                    }}
                    isLoading={isLoading}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchbarForm;

